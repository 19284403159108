<template>
  <div
    v-if="Object.keys(plan).length"
    class="
      h-100
      w-100
      d-flex
      justify-content-center
      align-items-center
      position-absolute
    "
    id="app"
  >
    <div class="box px-5 py-5 border-left-0 rounded">
      <div class="align-items-center d-flex flex-column">
        <div class="form-group">
          <label for="name">Name</label>
          <input
            type="text"
            class="form-control"
            id="name"
            aria-describedby="name"
            placeholder="Enter name"
            v-model="name"
          />
        </div>
        <div class="form-group">
          <label for="mobile">Mobile</label>
          <input
            type="text"
            class="form-control"
            id="mob"
            aria-describedby="mob"
            placeholder="Enter 10 digit mobile number"
            v-model="mobile"
          />
        </div>
        <button class="btn btn-danger px-5 py-3" @click="payMoney">
          <span
            ><b>Pay {{ plan["price"] }}</b></span
          >
        </button>
      </div>
    </div>
    <form
      method="post"
      action="https://securegw.paytm.in/theia/api/v1/showPaymentPage"
      name="paytm"
    >
      <table border="1">
        <tbody>
          <input type="hidden" name="mid" value="WXpRgY89144646425899" />
          <input
            type="hidden"
            name="callbackUrl"
            value="https://Pysberduck.pythonanywhere.com/api/payment_callback"
          />
          <input type="hidden" name="orderId" id="orderId" />
          <input type="hidden" name="txnToken" id="txnToken" />
        </tbody>
      </table>
    </form>
  </div>
</template>

<script>
import db from "@/firebase";
import axios from "axios";
import store from "@/store";
export default {
  props: ["plan_name"],

  async mounted() {
    console.log(this.plan_name);
    let doc = await db.collection("pricing").doc(this.plan_name).get();
    if (doc.exists) {
      console.log("Doc found");
      this.plan = doc.data();
      console.log(this.plan);
    } else {
      console.log("Doc not found");
    }
    store.state.loading = false;
  },
  data() {
    return {
      plan: {},
      name: "",
      mobile: "",
    };
  },
  methods: {
    payMoney() {
      if (this.name == "" || this.mobile == "") {
        swal("Please enter valid fields");
      }
      axios
        .post("https://Pysberduck.pythonanywhere.com/api/payment", {
          name: this.name,
          mobile: this.mobile,
          plan_name: this.plan_name,
        })
        .then(
          (res) => {
            if (res.data == {}) {
              swal("Some error occured");
              return;
            }
            document.getElementById("orderId").value =
              res.data["body"]["orderId"];
            document.getElementById("txnToken").value =
              res.data["body"]["txnToken"];
            document.paytm.submit();
          },
          (err) => {
            swal("Some error occured");
          }
        )
        .catch((err) => swal(err.toString()));
    },
  },
};
</script>

<style scoped>
#app {
  background-color: #ffeaea;
}
.box {
  background-color: #ffb9b9;
  padding: 50 px;
}
</style>